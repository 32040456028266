import {useState, useEffect, useCallback} from 'react';
import * as constants from "../helper/constants";

export default function useTitleAnimation() {
    const [isHtmlProcessed, setIsHtmlProcessed] = useState(false);
    const [windowSize, setWindowSize] = useState(false);

    const hasClass = (ele, cls) => {
        return !!ele.className.match(new RegExp('(\\s|^)' + cls + '(\\s|$)'));
    };

    const addClass = useCallback((ele, cls) => {
        if (!hasClass(ele, cls)) ele.className += " " + cls;
    }, []);

    const removeClass = useCallback((ele, cls) => {
        if (hasClass(ele, cls)) {
            const reg = new RegExp('(\\s|^)' + cls + '(\\s|$)');
            ele.className = ele.className.replace(reg, ' ');
        }
    }, []);

    useEffect(() => {
        const content = document.getElementsByClassName('spring-animation');
        const h5animation = document.getElementsByClassName('h5-animation');
        setWindowSize(window.innerWidth < constants.MOBILE_WIDTH);

        if (!content[0]) {
            return
        }

        window.setTimeout(() => {
            if(h5animation[0]){
                h5animation[0].style.opacity = 1;
            }
        }, windowSize ? 0 : 2000);

        Array.from(content).forEach(function (item) {
            const delay = item.dataset.delay;
            const background = item.dataset.background;

            if (background) {
                removeClass(item, 'with-background');
                addClass(item, 'no-background-temp');
            }

            item.style.display = 'inline-block';
            item.style.transform = windowSize ? 'translateY(0)' : 'translateY(100%)';

            window.setTimeout(() => {
                item.style.transition = 'all 1.1s ease';
                item.style.transform = 'translateY(0)';

                if (background) {
                    window.setTimeout(() => {
                        addClass(item, 'with-background');
                    }, 1000)
                }
            }, delay || 500)
        });

        setIsHtmlProcessed(true);

    }, [addClass, removeClass, windowSize]);

    return isHtmlProcessed;
}
