import React from "react"
import {graphql} from "gatsby"
import PagePart from "../../components/template-parts/page-part"
import PagePartHome from "../../components/template-parts/page-part-home"

export default ({data}) => data.page.isFrontPage ? <PagePartHome data={data} isFrontPage={data.page.isFrontPage}/> : <PagePart data={data}/>

export const query = graphql`
    query page($id: String!) {
        homepageBlogPosts: allWpPost(
            limit: 3,
            filter: { nodeType: { in: ["Post"] },
            postFields: {visibleAtHomepage: {in: ["Yes"]}}}, 
            sort: {fields: date, order: DESC}) {
                nodes {
                    tags {
                        nodes {
                            link
                            name
                        }
                    }
                    id
                    uri
                    title
                    date(formatString: "DD MMM YYYY")
                    author {
                        node {
                            name
                        }
                    }
                    postFields {
                        homeText
                        visibleAtHomepage
                        showOnhomeLastPost
                    }
                    featuredImage {
                        node {
                            sourceUrl
                            remoteFile {
                                ...Thumbnail
                            }
                        }
                    }
            }
        }
        
        page: wpPage(id: { eq: $id }) {
            title
            content
            isFrontPage
            featuredImage {
                node {
                    sourceUrl
                    remoteFile {
                        ...HeroImage
                }
            }
        }
        template {
            __typename
        }
        seo {
            title
            metaDesc
            canonical
        }
        pageTitleLargeContentFieldset {
            fieldGroupName
            pageTitleLargeContent {
                backgroundColor
                button
                buttonSize
                fieldGroupName
                link {
                    fieldGroupName
                    text
                    url
                }
                mainDescription
                textColor
                title
                image {
                    remoteFile {
                        childImageSharp {
                            fluid(quality: 100) {
                                ...GatsbyImageSharpFluid
                            }
                        }
                    }
                }
            }
        }
        servisesQuestionsLeft {
            anyQuestionsLeft {
                content {
                    contentWsw
                    fieldGroupName
                    internalList {
                        description
                        fieldGroupName
                    }
                }
                fieldGroupName
                title
            }
            fieldGroupName
            title
        }
        additionalMetaTag {
            keywords
            ogDescription
            ogTitle
            ogType
                ogImage {
                    mediaItemUrl
                }
            twitterImage{
                mediaItemUrl
            }
            twitterTitle
            twitterDescription
        }
        
        caseStudiesTitleBlock {
            block {
                titleH2
                titleH5
                description
                linkTarget
                linkText
                fieldGroupName
                caseStudies {
                    ... on WpCaseStudy {
                        id
                        slug
                        title
                        content
                        link
                        contentType {
                            node {
                                graphqlSingleName
                                name
                            }
                        }
                        caseStudySingleFeatured {
                            additionalFields {
                                titleH5
                                clientFigures
                            }   
                        }
                        caseStudyRegions {
                            nodes {
                                id
                                name
                            }
                        }
                        caseStudyIndustries {
                            nodes {
                                id
                                name
                            }
                        }
                        caseStudyTechnologies {
                            nodes {
                                id
                                name
                            }
                        }
                        featuredImage {
                            node {
                                sourceUrl
                                remoteFile {
                                    ...Thumbnail
                                }
                            }
                        }
                        info {
                            aboutClient
                            aboutClientShort
                            brand
                            result
                            gridImage {
                                mediaItemUrl
                                title
                                remoteFile {
                                    childImageSharp {
                                        fluid(quality: 90) {
                                            ...GatsbyImageSharpFluid
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            } 
        }
    }
    homepage: wpPage(isFrontPage: {in: true}) {
        homepageCustomFields {
            testimonialsBlock {
                fieldGroupName
                button
                titleH5
                titleH2
            }
       }
       
       testimonialsFields {
          fieldGroupName
          button
          titleH5
          titleH2
       }
    }
    testimonials: allWpTestimonial(sort: { fields: date, order: DESC }) {
        nodes {
            slug
            title
            id
            testimonials {
                title
                authorName
                authorPosition
                testimonial
                image {
                    remoteFile {
                        childImageSharp {
                            fluid(quality: 100) {
                                ...GatsbyImageSharpFluid
                            }
                        }
                    }    
                }
            }
            
            testimonialsCustomFields {
                caseStudy {
                    ... on WpCaseStudy {
                        id
                        uri
                    }
                }
                events {
                    ... on WpEvent {
                        id
                        uri
                        }
                    }
                }
                contentType {
                    node {
                        graphqlSingleName
                    }
                }
            }
        }
   }
  
`
