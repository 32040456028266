
//To add the src attribute for video tag in admin content

export default function addVideoLink(siteUrl){
    const videoContainer = document.getElementsByClassName('replace-src');

    Array.from(videoContainer).forEach(function (item) {
        const src =  item.getAttribute("data-src");

        item.setAttribute('src', `${siteUrl + src}`);
    });
}
