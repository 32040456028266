import React, {useState} from "react"
import sanitizeHtml from 'sanitize-html';
import Img from "gatsby-image"
import Layout from "../../components/layout"
import sanitizeOptions from "../../helper/sanitizeOptions";
import TestimonialsHome from "../homepage/testimonials";
import {Helmet} from "react-helmet";
import TitleLarge from "../title-large-content";
import {ItemPost} from "../posts-list/ItemPost";
import ServiceAccordion from "../service-accordion";
import TitleLists from "../title-lists";
import AniLink from "gatsby-plugin-transition-link/AniLink";
import {normalizePath} from "../../utils/get-url-path";

function PagePart({data}) {
    const sanitizeOpt = sanitizeOptions();
    const {page} = data;
    const {title, content, featuredImage, seo, additionalMetaTag} = page;
    // Set metadata
    const seoData = {...{seo}, additionalMetaTag, title};

    // WpServiceTemplate
    let caseStudyArray = Array.isArray(data.testimonials?.nodes) && data.testimonials?.nodes.filter((el) => (!el?.testimonialsCustomFields?.events));
    const caseStudyTitleBlock = data.page.caseStudiesTitleBlock.block || {};
    const templateType = data?.page?.template?.__typename;
    const homepageBlogPosts = data?.homepageBlogPosts;
    const homepage = data?.homepage;
    const pageTitleLargeContent = data?.page?.pageTitleLargeContentFieldset?.pageTitleLargeContent;
    const servicesQuestionsLeft = data?.page?.servisesQuestionsLeft;

    return (
        <Layout
            setClass={'page-wrapper-wp'}
            seoData={seoData}>
            {templateType === "WpServiceTemplate" && (
                <Helmet
                    bodyAttributes={{
                        class: 'services-page dark-background'
                    }}
                />
            )}

            {templateType !== "WpServiceTemplate" && (
                <h1 className={`title`}>
                    {title}
                </h1>
            )}

            {templateType === "WpServiceTemplate" && pageTitleLargeContent && (
                <div className={'main-title-wrapper'}>
                    <TitleLarge
                        data={pageTitleLargeContent}/>
                </div>

            )}

            {!!featuredImage?.node?.remoteFile?.childImageSharp && (
                <div>
                    <Img fluid={featuredImage.node.remoteFile.childImageSharp.fluid}/>
                </div>
            )}

            <div dangerouslySetInnerHTML={{__html: sanitizeHtml(content, sanitizeOpt.contentData)}}/>

            {templateType === "WpServiceTemplate" && homepage && caseStudyArray && (
                <div className={'page-block testimonials-block'}>
                    <div className={'bottom-content'}>
                        <TestimonialsHome
                            testimonials={caseStudyArray}
                            homepage={data.homepage.testimonialsFields}/>
                    </div>
                </div>
            )}


            {templateType === "WpServiceTemplate" && caseStudyTitleBlock && (
                <div className={'page-content case-studies-block-container'}>
                    <TitleLists data={{
                        customFields: 'case_studies_block',
                        titleH2: caseStudyTitleBlock.titleH2,
                        titleH5: caseStudyTitleBlock?.titleH5,
                        description: caseStudyTitleBlock.description,
                        link: caseStudyTitleBlock.linkTarget,
                        button: caseStudyTitleBlock.linkText,
                        caseStudies: caseStudyTitleBlock.caseStudies,
                    }}/>
                </div>
            )}

            {templateType === "WpServiceTemplate" && homepageBlogPosts && (
                <div className={'page-content grid blog-wrapper'}>
                    <div className={'top-content'}>
                        <div className={'left-container flex-container'}>
                            <h2 className={'black h3-title-services'}>
                                {'Explore ecommerce trends and news'}
                            </h2>
                        </div>
                        <div className={'right-container'}
                             data-animated-group="animated-content">
                            <AniLink to={normalizePath('blog')}
                                     paintDrip
                                     duration={1}
                                     hex="#ed5d2b"
                                     className={'transition-link paragraph-primary__bold mc-link main-link mc-button-secondary right link-font-styles'}>
                                {'Discover our blog'}
                            </AniLink>
                        </div>
                    </div>
                    <ul className={'blog-list grid full-width'}>
                        {homepageBlogPosts.nodes.map((post, i) => (
                                <ItemPost
                                    i={i}
                                    key={i + post?.uri}
                                    uri={post?.uri}
                                    title={post?.title}
                                    date={post?.date}
                                    author={post?.author}
                                    postFields={post?.postFields}
                                    featuredImage={post?.featuredImage}
                                    tags={post?.tags}
                                />
                            )
                        )}
                    </ul>
                </div>
            )}

            {!!servicesQuestionsLeft?.anyQuestionsLeft && (
                <div className={'service-accordion page-content'}>
                    <ServiceAccordion
                        title={servicesQuestionsLeft.title}
                        titlePath={servicesQuestionsLeft.anyQuestionsLeft.title}
                        descPath={servicesQuestionsLeft.anyQuestionsLeft.content}
                        items={servicesQuestionsLeft.anyQuestionsLeft}
                    />
                </div>
            )}
        </Layout>
    )
}

export default PagePart
