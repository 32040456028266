import React from "react";
import {useStaticQuery, graphql} from "gatsby";
import Layout from "../../components/layout";
import TitleLarge from "../title-large-content";
import TestimonialsHome from "../../components/homepage/testimonials";
import {Helmet} from "react-helmet";
import sanitizeHtml from "sanitize-html";
import sanitizeOptions from "../../helper/sanitizeOptions";
import ServiceAccordion from "../service-accordion";
import AniLink from "gatsby-plugin-transition-link/AniLink";
import {normalizePath} from "../../utils/get-url-path";
import {ItemPost} from "../posts-list/ItemPost";
import TitleLists from "../title-lists";

const Homepage = props => {
    const data = useStaticQuery(graphql`
        {
            homepage: wpPage(isFrontPage: {in: true}){
                content
                homepageCustomFields {
                    titleLargeContent{
                      title
                      tagline
                      socialLinks
                      button
                    }
                    caseStudiesBlock {
                      fieldGroupName
                      titleH2
                      titleH5
                      description
                      button
                      link
                    }
                    blogBlock {
                      fieldGroupName
                      titleH2
                      titleH5
                      button
                      link
                    }
                    testimonialsBlock {
                        fieldGroupName
                        button
                        titleH5
                        titleH2
                        clientImagesGroup{
                        linksList
                        imagesList{
                                id
                                sourceUrl
                                remoteFile {
                                ...Thumbnail 
                                }
                            }
                        }
                    }
                }
                pageTitleLargeContentFieldset {
                    fieldGroupName
                    pageTitleLargeContent {
                        backgroundColor
                        button
                        buttonSize
                        fieldGroupName
                        link {
                            fieldGroupName
                            text
                            url
                        }
                        mainDescription
                        textColor
                        title
                        image {
                            remoteFile {
                                childImageSharp {
                                    fluid(quality: 100) {
                                        ...GatsbyImageSharpFluid
                                    }
                                }
                            }
                        }
                        additionalText
                    }
                }
                caseStudiesTitleBlock {
                    block {
                        titleH2
                        titleH5
                        description
                        linkTarget
                        linkText
                        fieldGroupName
                        caseStudies {
                            ... on WpCaseStudy {
                                id
                                slug
                                title
                                link
                                contentType {
                                    node {
                                        graphqlSingleName
                                        name
                                    }
                                }
                                caseStudySingleFeatured {
                                    additionalFields {
                                        titleH5
                                        clientFigures
                                    }   
                                }
                                caseStudyRegions {
                                    nodes {
                                        id
                                        name
                                    }
                                }
                                caseStudyIndustries {
                                    nodes {
                                        id
                                        name
                                    }
                                }
                                caseStudyTechnologies {
                                    nodes {
                                        id
                                        name
                                    }
                                }
                                featuredImage {
                                    node {
                                        sourceUrl
                                        remoteFile {
                                            ...Thumbnail
                                        }
                                    }
                                }
                                info {
                                    aboutClient
                                    aboutClientShort
                                    brand
                                    result
                                    gridImage {
                                        mediaItemUrl
                                        title
                                        remoteFile {
                                            childImageSharp {
                                                fluid(quality: 90) {
                                                    ...GatsbyImageSharpFluid
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    } 
                }
                 testimonialsFields {
                        fieldGroupName
                        button
                        titleH5
                        titleH2
                 }
                servisesQuestionsLeft {
                    anyQuestionsLeft {
                        content {
                            contentWsw
                            fieldGroupName
                            internalList {
                                description
                                fieldGroupName
                            }
                        }
                        fieldGroupName
                        title
                    }
                    fieldGroupName
                    title
                }
                seo {
                    title
                    metaDesc
                    canonical
                }
                additionalMetaTag {
                    keywords
                    ogDescription
                    ogTitle
                    ogType
                    ogImage {
                        mediaItemUrl
                    }
                    twitterImage {
                        mediaItemUrl
                    }
                    twitterTitle
                    twitterDescription
                }
            }
            
            contactBlock: wpPage(slug: {eq: "contacts"}) {
                contactUsCustomFields {
                    contactUsBlock {
                        description
                        text
                        titleH2
                        titleH4
                    }
                }
            }
            
            homepagePortfolio: allWpPortfolio(limit: 4) {
                nodes {
                    slug
                    id
                    title
                    contentType {
                        node {
                            graphqlSingleName
                        }
                    }
                }
            }
  
            homepageBlogPosts: allWpPost(
            limit: 3,
            filter: { nodeType: { in: ["Post"] },
            postFields: {visibleAtHomepage: {in: ["Yes"]}}}, 
            sort: {fields: date, order: DESC}
            ) {
                nodes {
                    tags {
                        nodes {
                            link
                            name
                        }
                    }
                    id
                    uri
                    title
                    date(formatString: "DD MMM YYYY")
                    author {
                        node {
                        name
                    }
                }
                postFields {
                    homeText
                    visibleAtHomepage
                    showOnhomeLastPost
                }
                featuredImage {
                    node {
                        sourceUrl
                        remoteFile {
                            ...Thumbnail
                            }
                        }
                    }
                }
            }
            
            homepageServicesBlock: allWpPost(
                limit: 3
                filter: {categories: {nodes: {elemMatch: {slug: {eq: "services"}}}}}
                sort: {fields: date, order: DESC}
            ) {
                nodes {
                id
                title
                uri
                postFields {
                   homeText
                   textButtonServices
                    }
                }
            }
            
            testimonials: allWpTestimonial(
            sort: { fields: date, order: DESC }
            ) {
                nodes {
                    slug
                    title
                    id
                    testimonials {
                        title
                        authorName
                        authorPosition
                        testimonial
                        image {
                            remoteFile {
                                childImageSharp {
                                    fluid(quality: 100) {
                                         ...GatsbyImageSharpFluid
                                    }
                                }
                                publicURL
                            }
                        }
                    }
                    testimonialsCustomFields {
                        caseStudy {
                        ... on WpCaseStudy {
                                id
                                uri
                            }
                        }
                        category
                        events {
                            ... on WpEvent {
                                id
                                uri
                            }
                        }
                        fieldGroupName
                    }
                    contentType {
                        node {
                            graphqlSingleName
                        }
                    }
                }
            }
        }
    `)

    const sanitizeOpt = sanitizeOptions();

    const {
        contactBlock,
        homepage,
        homepageBlogPosts,
        testimonials
    } = data;

    const {seo, additionalMetaTag} = homepage;
    const {contactUsCustomFields} = contactBlock
    // Set metadata
    const seoData = {...{seo}, additionalMetaTag};
    let caseStudyArray = Array.isArray(testimonials?.nodes) && testimonials?.nodes
        .filter((el) => (!el?.testimonialsCustomFields?.events))
        .filter((item) => item?.testimonialsCustomFields?.category !== 'agencies');

    const pageTitleLargeContent = data?.homepage?.pageTitleLargeContentFieldset?.pageTitleLargeContent;
    const anyQuestionsLeftData = data?.homepage?.servisesQuestionsLeft;
    const caseStudyTitleBlock = data.homepage.caseStudiesTitleBlock.block || {};

    return (
        <Layout
            seoData={seoData}>
            <Helmet
                bodyAttributes={{
                    class: 'home-wrapper'
                }}
            />
            <div className={'home-content-page'}>
                {pageTitleLargeContent && (
                    <div className={'main-title-wrapper'}>
                        <TitleLarge
                            data={pageTitleLargeContent}
                            isFrontPage={props.isFrontPage}/>
                    </div>
                )}

                <div dangerouslySetInnerHTML={{__html: sanitizeHtml(homepage?.content, sanitizeOpt.contentData)}}/>


                <div className={'home-content-container'}>
                    <div className={'page-block testimonials-block'}>
                        <div className={'bottom-content'}>
                            <TestimonialsHome
                                testimonials={caseStudyArray}
                                homepage={data?.homepage?.testimonialsFields}
                            />
                        </div>
                    </div>
                </div>

                {caseStudyTitleBlock && (
                    <div className={'page-content case-studies-block-container'}>
                        <TitleLists data={{
                            customFields: 'case_studies_block',
                            titleH2: caseStudyTitleBlock.titleH2,
                            titleH5: caseStudyTitleBlock?.titleH5,
                            description: caseStudyTitleBlock.description,
                            link: caseStudyTitleBlock.linkTarget,
                            button: caseStudyTitleBlock.linkText,
                            caseStudies: caseStudyTitleBlock.caseStudies,
                        }}/>
                    </div>
                )}

                <div className={'page-content grid blog-wrapper'}>
                    <div className={'top-content'}>
                        <div className={'left-container flex-container'}>
                            <h2 className={'black h3-title-services'}>
                                {'Explore ecommerce trends and news'}
                            </h2>
                        </div>
                        <div className={'right-container'}
                             data-animated-group="animated-content">
                            <AniLink to={normalizePath('blog')}
                                     paintDrip
                                     duration={1}
                                     hex="#ed5d2b"
                                     className={'transition-link paragraph-primary__bold mc-link main-link mc-button-secondary right link-font-styles'}>
                                {'Discover our blog'}
                            </AniLink>
                        </div>
                    </div>
                    <ul className={'blog-list grid full-width'}>
                        {homepageBlogPosts.nodes.map((post, i) => (
                                <ItemPost
                                    i={i}
                                    key={i + post?.uri}
                                    uri={post?.uri}
                                    title={post?.title}
                                    date={post?.date}
                                    author={post?.author}
                                    postFields={post?.postFields}
                                    featuredImage={post?.featuredImage}
                                    tags={post?.tags}
                                />
                            )
                        )}
                    </ul>
                </div>

                {!!anyQuestionsLeftData?.anyQuestionsLeft && (
                    <div className={'service-accordion page-content'}>
                        <ServiceAccordion
                            title={anyQuestionsLeftData.title}
                            titlePath={anyQuestionsLeftData.anyQuestionsLeft.title}
                            descPath={anyQuestionsLeftData.anyQuestionsLeft.content}
                            items={anyQuestionsLeftData.anyQuestionsLeft}
                        />
                    </div>
                )}
            </div>
        </Layout>
    )
}

export default Homepage
